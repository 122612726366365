.App {
  width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;
  transition: color 1s ease-in-out;
}

.App.Dark {
  color: white;
}

.App .Icons {
  position: fixed;
  top: 0;
  right: 0;

  font-size: x-large;
}

.Icons {
  display: flex;
}

.Icons .Icon {
  color: inherit;
  padding: 0.6em;
}